<template>
  <v-dialog persistent v-model="showDelete" width="500">
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text" primary-title>{{$t("delete") }}</v-card-title>
      <v-card-text class="mt-5 pb-0">
        <span class="black--text">{{ $t("areYouSureDelete") }}</span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="onClose" outlined color="#616160">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          @click="confirmDelete"
          :loading="loading"
          color="red"
          class="white--text"
        >
          {{ $t("delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteWarning',
  props: ['onClose', 'confirmDelete', 'showDelete', 'loading']
};
</script>
